import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
    return request.post(constant.chargingServerUrl + "/shareProfitRecordDetail/pageList", formData);
}

function list(){
    return request.post(constant.chargingServerUrl + "/shareProfitRecordDetail/list");
}

function create(){
    return request.get(constant.chargingServerUrl + "/shareProfitRecordDetail/create");
}
  
function edit(id){
return request.get(constant.chargingServerUrl + "/shareProfitRecordDetail/edit/" + id);
}

function add(formModel){
return request.post(constant.chargingServerUrl + "/shareProfitRecordDetail/add", formModel,{
    headers: {
    "Content-Type": "application/json"
    }
});
}

function update(formModel){  
return request.post(constant.chargingServerUrl + "/shareProfitRecordDetail/update", formModel,{
    headers: {
    "Content-Type": "application/json"
    }
});
}

function remove(id){
return request.post(constant.chargingServerUrl + "/shareProfitRecordDetail/delete/" + id);
}

function batchRemove(idList){
return request.post(constant.chargingServerUrl + "/shareProfitRecordDetail/batchDelete",idList,{
    headers: {
    "Content-Type": "application/json"
    }
});
}

function testAddProfitRecordDetail() {
    return request.post(constant.chargingServerUrl + "/shareProfitRecordDetail/testAddProfitRecordDetail");
}



export default {
    pageList,create,edit,add,update,remove,batchRemove,list,testAddProfitRecordDetail
}