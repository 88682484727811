import { render, staticRenderFns } from "./shareProfitRecordDetail-list.vue?vue&type=template&id=82164d9a&scoped=true"
import script from "./shareProfitRecordDetail-list.vue?vue&type=script&lang=js"
export * from "./shareProfitRecordDetail-list.vue?vue&type=script&lang=js"
import style0 from "./shareProfitRecordDetail-list.vue?vue&type=style&index=0&id=82164d9a&prod&rel=stylesheet%2Fscss&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82164d9a",
  null
  
)

export default component.exports